import React from 'react';
import times from 'lodash/times';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Skeleton from '@mui/material/Skeleton';
import { useInView } from 'react-intersection-observer';
import { useKeenSlider, KeenSliderPlugin } from 'keen-slider/react';
import { DealPostType } from '@types';
import ProductCarouselCard from './components/ProductCarouselCard';
import { getRandomAnimationNumber, ProductCarouselProps } from './utils';

const ProductCarousel = ({
  products,
  title,
  tag,
  onClick,
  seeAllText,
  seeAllClick,
  loading = false,
  lazyLoad = true,
  shouldAnimate = false,
  afterChange = () => {},
  showSeeAllCard = true,
  seeAllButtonText = 'See all',
  showKeenSlider = true
}: ProductCarouselProps) => {
  const plusAmount = seeAllClick ? 1 : 0;
  const numSlides = products?.length ? products.length + plusAmount : 12;

  const animation = {
    duration: getRandomAnimationNumber(),
    easing: (t: number) => t
  };

  const WheelControls: KeenSliderPlugin = (slider) => {
    let touchTimeout: ReturnType<typeof setTimeout>;
    let position: {
      x: number;
      y: number;
    };
    let wheelActive: boolean;

    function dispatch(e: WheelEvent, name: string) {
      position.x -= e.deltaX;
      position.y -= e.deltaY;

      slider.container.dispatchEvent(
        new CustomEvent(name, {
          detail: {
            x: position.x,
            y: position.y
          }
        })
      );
    }

    function wheelStart(e: WheelEvent) {
      position = {
        x: e.pageX,
        y: e.pageY
      };
      dispatch(e, 'ksDragStart');
    }

    function wheel(e: WheelEvent) {
      dispatch(e, 'ksDrag');
    }

    function wheelEnd(e: WheelEvent) {
      dispatch(e, 'ksDragEnd');
    }

    function eventWheel(e: WheelEvent) {
      // only intercept horizontal scroll
      if (
        (e.deltaX > 0 && Object.is(e.deltaY, -0)) ||
        (e.deltaX <= 0 && Object.is(e.deltaY, -0))
      ) {
        e.preventDefault();
        if (!wheelActive) {
          wheelStart(e);
          wheelActive = true;
        }
        wheel(e);
        clearTimeout(touchTimeout);
        touchTimeout = setTimeout(() => {
          wheelActive = false;
          wheelEnd(e);
        }, 50);
      }
    }

    slider.on('created', () => {
      slider.container.addEventListener('wheel', eventWheel, {
        passive: false
      });
    });
  };

  const [sliderRef, instanceRef] = useKeenSlider(
    {
      slideChanged(slider) {
        // Details of the slider
        // console.log('details', slider.track.details);
        // TODO: Only log if the scroll was triggered by the user
        if (!shouldAnimate) {
          afterChange(slider.track.details.rel);
        }
      },
      renderMode: 'performance',
      loop: true,
      slides: {
        perView: 1,
        number: numSlides,
        origin: 'auto'
      },
      mode: 'free-snap',
      created(s) {
        if (shouldAnimate) {
          s.moveToIdx(5, true, animation);
        }
      },
      updated(s) {
        if (shouldAnimate) {
          s.moveToIdx(s.track.details.abs + 5, true, animation);
        }
      },
      animationEnded(s) {
        if (shouldAnimate) {
          s.moveToIdx(s.track.details.abs + 5, true, animation);
        }
      },
      breakpoints: {
        // XS mobile 1 slide
        '(max-width: 290px)': {
          slides: {
            perView: 1.3,
            number: numSlides,
            origin: 'auto'
          }
        },
        // Mobile 2 slides
        '(min-width: 390px)': {
          slides: {
            perView: 2,
            number: numSlides,
            origin: 'auto'
          }
        },
        // Mobile 3 slides
        '(min-width: 460px)': {
          slides: {
            perView: 2.3,
            number: numSlides,
            origin: 'auto'
          }
        },
        // Tablet 3 slides
        '(min-width: 630px)': {
          slides: {
            perView: 3.3,
            number: numSlides,
            origin: 'auto'
          }
        },
        // Desktop 4 slides
        '(min-width: 820px)': {
          slides: {
            perView: 4.3,
            number: numSlides,
            origin: 'auto'
          }
        },
        // Desktop small 5 slides
        '(min-width: 1200px)': {
          slides: {
            perView: 5.3,
            number: numSlides,
            origin: 'auto'
          }
        },
        // Desktop large 6 slides
        '(min-width: 1400px)': {
          slides: {
            perView: 6.3,
            number: numSlides,
            origin: 'auto'
          }
        },
        // Super large desktop 7 slides
        '(min-width: 1800px)': {
          slides: {
            perView: 7.3,
            number: numSlides,
            origin: 'auto'
          }
        }
      }
    },
    [WheelControls]
  );

  const [ref, inView] = useInView({
    triggerOnce: true, // Change this to false if you want the event to trigger repeatedly
    rootMargin: '200px 0px' // 200px margin on the top
  });

  const showCarousel = inView || lazyLoad === false;

  const renderSlides = () => {
    return (
      <>
        {loading &&
          times(12, (i: number) => {
            return (
              <Box className="keen-slider__slide" key={i}>
                <Skeleton
                  variant="rectangular"
                  width={210}
                  height={118}
                  key={i}
                  sx={{
                    backgroundColor: '#FFF',
                    color: 'rgba(0, 0, 0, 0.87)',
                    transition:
                      'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    borderRadius: '4px',
                    overflow: 'hidden',
                    maxWidth: '345px',
                    minWidth: 'calc(100% - 32px)',
                    margin: '16px',
                    height: 'calc(100% - 32px)',
                    minHeight: '320px'
                  }}
                />
              </Box>
            );
          })}
        {!loading &&
          (products || []).map((product: DealPostType) => {
            if (showKeenSlider) {
              return (
                <ProductCarouselCard
                  tag={tag}
                  onClick={onClick}
                  key={product.ASIN}
                  {...product}
                  className="keen-slider__slide"
                />
              );
            }

            return (
              <Grid
                id="item"
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                key={product.ASIN}
              >
                <ProductCarouselCard
                  tag={tag}
                  onClick={onClick}
                  key={product.ASIN}
                  {...product}
                  sx={{
                    position: 'relative',
                    height: '100%'
                  }}
                />
              </Grid>
            );
          })}
        {!loading && seeAllClick && showSeeAllCard && (
          <Box className="keen-slider__slide" key="see-all">
            <ButtonBase
              sx={{
                height: 'calc(100% - 16px)',
                maxWidth: '325px',
                minWidth: 'calc(100% - 16px)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
                textTransform: 'uppercase',
                border: '1px solid #bdc6ca',
                margin: '8px'
              }}
              aria-label={seeAllText}
              onClick={seeAllClick}
            >
              <Typography
                sx={{
                  fontSize: '20px'
                }}
              >
                {seeAllButtonText}
              </Typography>
            </ButtonBase>
          </Box>
        )}
      </>
    );
  };

  return (
    <Box ref={ref}>
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <>
            {title && (
              <Typography
                component="h2"
                variant="h5"
                sx={{ mt: '4px', fontSize: '1.3rem' }}
                data-testid="product-carousel-title"
              >
                {title}
              </Typography>
            )}
            {seeAllClick && (
              <Button
                onClick={seeAllClick}
                sx={{
                  minWidth: '75px',
                  marginLeft: '4px'
                }}
              >
                {seeAllButtonText}
              </Button>
            )}
          </>
        </Box>
        {showCarousel && (
          <Box
            sx={{
              backgroundColor: '#d2d2d245',
              marginBottom: '16px'
            }}
          >
            {showKeenSlider && (
              <Box
                ref={sliderRef}
                className="keen-slider"
                onMouseEnter={() => {
                  if (instanceRef.current && shouldAnimate) {
                    instanceRef.current.animator.stop();
                  }
                }}
                onMouseLeave={() => {
                  if (instanceRef.current && shouldAnimate) {
                    instanceRef.current.moveToIdx(
                      instanceRef.current.track.details.abs + 5,
                      true,
                      animation
                    );
                  }
                }}
              >
                {renderSlides()}
              </Box>
            )}
            {!showKeenSlider && (
              <Grid container sx={{ mt: 1 }} spacing={0.5}>
                {renderSlides()}
              </Grid>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ProductCarousel;
